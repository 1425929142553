<template>
  <div class="position-relative">
    <div class="bg-light">
      <div class="container py-5">
        <div class="row h-100 align-items-center py-5">
          <div class="col-12 col-lg-12 col-md-12 col-sm-12 mt-5">
            <h3 class="display-4 font-weight-bold text-white mt-5">Contact Us</h3>
            <p class="lead text-white mb-5">
            For more information, fill in your contact detail and we will get back to you 
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 mb-5">
      <div class="container">
        <h5 class="text-center py-3 mb-3">Contact us for more information and get a service that fits your needs which has been proven by our experience in delivering products to various industrial sectors and regions</h5>
        <div class="row pb-5">
          <div class="col-12 col-lg-12 col-md-12 col-sm-12">
            <div class="">
              <div class="card flex-row text-left mb-5">
                <img class="card-img-left example-card-img-responsive" src="@/assets/images/customer-service.png" width="100" height="100"/>
                <div class="card-body text-start p-3">
                  <h4 class="card-title h5 h4-sm font-weight-bold">Contact</h4>
                  <ul style="list-style: none;">
                    <li>Email : mimologistics1@gmail.com</li>
                    <li>+62 812-1813-4798</li>
                  </ul>
                </div>
              </div>
              <div class="card flex-row text-left">
                <img class="card-img-left example-card-img-responsive" src="@/assets/images/working-time.png" width="100" height="100"/>
                <div class="card-body text-start">
                  <h4 class="card-title h5 h4-sm font-weight-bold">Office Hour</h4>
                  <p class="card-text">Mon - Fri 8:00 am - 5:00 pm</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card mt-3 shadow">
              <div class="card-body">
                <span
                  class="material-icons"
                  style="font-size: 110px; color: #00b894"
                >
                  whatsapp
                </span>
                <h4 class="f-bold cl-primary">
                  <a
                    href="https://api.whatsapp.com/send?phone=6281930456886&text=Hallo%20Mimo%20Logictics"
                    target="_blank"
                  >
                    WhatsApp</a
                  >
                </h4>
                <p class="card-text cl-secondary mt-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card mt-3 shadow">
              <div class="card-body">
                <span
                  class="material-icons"
                  style="font-size: 110px; color: #d63031"
                >
                  mail_outline
                </span>
                <h4 class="f-bold cl-primary">
                  <a href="mailto:logistics.mimo@gmail.com">Gmail</a>
                </h4>
                <p class="card-text cl-secondary mt-4">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contact",
};
</script>
